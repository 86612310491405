.h-60px {
  height: 50px !important;
}
.w-60px {
  width: 50px !important;
}


.loaderdiv{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #C6974B;

    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 