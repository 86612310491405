body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.h-60px {
  height: 50px !important;
}
.w-60px {
  width: 50px !important;
}


.loaderdiv{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #C6974B;

    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    -webkit-animation: rotation 1s linear infinite;
            animation: rotation 1s linear infinite;
    }

    @-webkit-keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    }

    @keyframes rotation {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
    } 
